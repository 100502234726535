import { SET_PAGES, FETCH_PAGES } from "../actions/action-types";
const intialState = {
  pages: [],
};

// export const pagesReducer = (state = intialState, { type, payload }) => {
//   switch (type) {
//     case ActionTypes.SET_PAGES:
//       return { ...state, pages: payload };
//     default:
//       return state;
//   }
// };

export const pageReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case SET_PAGES:
      return { ...state, pages: payload };
    case FETCH_PAGES:
      return { ...state, pages: payload };
    default:
      return state;
  }
};
